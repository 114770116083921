import React from 'react';
import { AppSvgTypes, AppSvgs } from './Svg.js';
import './App.css';

function FancyText(props) {
  let classes = 'bg-gradient-to-r from-blue-500 to-indigo-400  bg-clip-text text-transparent';
  if (props.inline) classes += ' inline';
  return (
    <div className={classes}>
      {props.children}
    </div>
  );
}

function App() {
  const GitHubSvg = AppSvgs[AppSvgTypes.GitHub];
  const LinkedInSvg = AppSvgs[AppSvgTypes.LinkedIn];
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-800 to-gray-900">
      <header className="text-center mb-8">
        <img
          className="mt-4 ml-8 mb-4 rounded-full"
          width="150px"
          src="https://2.gravatar.com/avatar/6f2b8c3959e04ab9d51387b7780ede488c2163f7eb946dd39fb7c5d7abae6e7c?size=256"
          srcset="https://2.gravatar.com/avatar/6f2b8c3959e04ab9d51387b7780ede488c2163f7eb946dd39fb7c5d7abae6e7c?size=256 1x, https://2.gravatar.com/avatar/6f2b8c3959e04ab9d51387b7780ede488c2163f7eb946dd39fb7c5d7abae6e7c?size=512 2x"
          alt="avatar"
        />
        <FancyText>
          <h1 className="text-4xl font-bold bg-clip-text">William Olson</h1>
        </FancyText>
        <FancyText>
          <p className="text-lg">Software Engineer</p>
        </FancyText>
      </header>

      <main className="bg-gray-800 shadow-lg rounded-lg p-6 max-w-lg w-full">
        <section>
          <h2 className="text-2xl font-semibold text-gray-400 mb-4">
            About Me
          </h2>
          <p className="text-gray-600">
            I am a passionate Software Engineer with experience in a variety of
            programming languages and technologies. I thrive on solving complex
            problems and delivering high-quality software solutions.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-400 mb-4">Skills</h2>
          <div className="grid grid-cols-2 gap-0 ">
            <ul className="list-disc list-inside text-gray-600">
              <li>
                Languages: 
                <ul className="list-disc list-inside ml-6">
                  <li>JavaScript </li>
                  <li>TypeScript </li>
                  <li>Java </li>
                  <li>Scala </li>
                  <li>Rust </li>
                  <li>Go </li>
                  <li>C </li>
                  <li>C++ </li>
                  <li>Python </li>
                  <li>C# </li>
                </ul>
              </li>
          </ul>
            <ul className="list-disc list-inside text-gray-600">
              <li>Proficiencies: 
                <ul className="list-disc list-inside ml-6">
                  <li>Microservices </li>
                  <li>Kubernetes </li>
                  <li>Elasticsearch </li>
                  <li>Redis </li>
                  <li>Amazon RDS </li>
                  <li>AWS Lambda </li>
                  <li>Firmware</li>
                  <li>IoT</li>
                  <li>Kafka</li>
                  <li>MQTT</li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-400 mb-4">
            Projects
          </h2>
          <p className="text-gray-600">
            Check out my <FancyText inline={true}><a href='https://github.com/William-Olson' rel='noreferrer' target='_blank'>GitHub </a></FancyText>
            to see some of the side projects I've worked on
            including serverless apps, command line tools, and Docker applications.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-400 mb-4">
            Get In Touch
          </h2>
          <p className="text-gray-600">
            I'm always open to discussing new opportunities and collaborations.
            Feel free to reach out!
          </p>
          <div className="grid grid-cols-4 gap-2 ">
            <div>
              <a
                href="mailto:willko747@gmail.com"
                className="mt-4 inline-block bg-gradient-to-r from-blue-500 to-indigo-400 text-white px-4 py-2 rounded hover:to-blue-700 transition duration-200"
              >
                Email
              </a>
            </div>
            <a href='https://github.com/William-Olson' rel='noreferrer' target='_blank' className="border-1">
              {GitHubSvg('slategray', { width: '65px', className: 'ml-1 mt-1' })}
            </a>
            <a href='https://www.linkedin.com/in/william-olson-27520b26/' rel='noreferrer' target='_blank' className=" border-1">
              {LinkedInSvg("#0a66c2", { width: '55px', className: 'mt-3 ml-1' })}
            </a>
            <a href='https://keybase.io/willko' rel='noreferrer' target='_blank' className="pt-2 border-1">
              <img src="https://keybase.io/images/icons/icon-keybase-logo-48.png" 
              srcset="https://keybase.io/images/icons/icon-keybase-logo-48@2x.png 2x" alt="keybase logo" />
            </a>
          </div>
        </section>
      </main>

      <footer className="mt-8">
       <FancyText>
          <p className="pb-4 ">© 2024 William Olson</p>
       </FancyText>
      </footer>
    </div>
  );
}

export default App;
